@font-face {
    font-family: "Display";  
    src: local("ProDisplay"),
      url("../assets/icons/fonts/pro-display/SFPRODISPLAYREGULAR.OTF") format("truetype");
  }
  
  @font-face {
    font-family: "Inter";   
    src: local("ProDisplay"),
      url("../assets/icons/fonts/inter/Inter-Medium.ttf") format("truetype");
  }
  
  html, body {
    height: 100%;
    width: 100%;
    background-color: black;
  }
  
  .small_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1C1C1E;
    border: 1px solid #1C1C1E;
    border-radius: 24px;
    width: 327px;
    height: 360px;
    gap: 20px;
    font-family: "Display";
    margin-bottom: 150px;
  }
  
  .container {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-bottom: 50px;
  }
  
  .header_container {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .containerLogo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12.5px
  }
  
  .containerLogo_text {
    font-weight: 600;
    line-height: 20px;
    font-size: 20px;
    letter-spacing: 0.15px;
    color:white;
    font-family: "Inter";
  }
  
  .mainPromoText {
    font-weight: 700;
    font-size: 20;
    letter-spacing: 0.15;
    text-align: center;
    width: '100%';
    color:white;
  }
  
  .mainPromoImage {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  
  .mainPromoImage_source {
    border-radius: 125px;
    width:108px;
    height: 108px
  }
  
  .mainDescriptionContainer{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 4px
  }
  
  .mainDescriptionContainer__name{
    font-weight: 500;
    font-size: 16;
    letter-spacing: -0.3;
    color:white;
  }
  
  .mainDescriptionContainer__texts{
    font-weight: 400;
    font-size: 16;
    color: #707070;
    font-style: italic;
  }
  
  .mainButtonWrapper {
    background: linear-gradient(283.27deg, #7E13FF 24.48%, #EB16D5 95.31%);
    width: 279px;
    height: 45px;
    border-radius: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .mainButton{
    color: white;
    font-size: 15px;
    font-weight: 700;
  }