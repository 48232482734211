@font-face {
    font-family: "Display";  
    src: local("ProDisplay"),
      url("../assets/icons/fonts/pro-display/SFPRODISPLAYREGULAR.OTF") format("truetype");
  }
  
  @font-face {
    font-family: "Inter";   
    src: local("ProDisplay"),
      url("../assets/icons/fonts/inter/Inter-Medium.ttf") format("truetype");
  }
  
  .anim_small_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #1C1C1E;
    border: 1px solid #1C1C1E;
    border-radius: 24px;
    width: 327px;
    height: 205px;
    gap: 20px;
    font-family: "Display";
  }
  
  .anim_container {
    background-color: black;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
  }
  
  .anim_header_container {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .anim_containerLogo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12.5px
  }
  
  .anim_containerLogo_text {
    font-weight: 600;
    line-height: 20px;
    font-size: 20px;
    letter-spacing: 0.15px;
    color:white;
    font-family: "Inter";
  }
  
  .anim_mainPromoText {
    font-weight: 500;
    font-size: 16px;
    font-family: "Display"; 
    letter-spacing: 0.15;
    text-align: center;
    width: 100%;
    color:white;
  }
